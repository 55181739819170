import React from "react"
import { Link, graphql } from "gatsby"
import Bio from "../components/bio"
import ListHeader from "../components/listHeader"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import "../style.css"
const TagsTemplate = ({ data, location, pageContext }) => {
  const results = data.articles.nodes
  const tag = pageContext.distinctTag

  if (results.length === 0) {
    return (
      <Layout location={location}>
        <Seo title={`Content Tag: ${tag}`} />
        {/* <ListHeader catalog={catalog} /> */}
        <p>No results.</p>
      </Layout>
    )
  }
  return (
    <Layout location={location}>
      <Seo title={`Content Tag: ${tag}`} />
      {/* <header>
        <h1 itemProp="headline">{tag}</h1>
        <p>Content tagged "{tag}"</p>
      </header> */}
      <ListHeader catalog={tag} />
      <ol style={{ listStyle: `none` }}>
        {results.map(post => {
          const title = post.frontmatter.title || post.slug
          const description = post.frontmatter.description || post.excerpt
          const categorySection =
            post.frontmatter.category === "post"
              ? "posts"
              : post.frontmatter.category === "project"
              ? "projects"
              : ""
          return (
            <li key={post.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <Header>
                  <h2>
                    <Link
                      to={`/${categorySection}/${post.slug}`}
                      itemProp="url"
                    >
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  <small>{post.frontmatter.date}</small>
                </Header>
                <section>
                  <p>{description}</p>
                </section>
                <hr />
                <section>
                  <Heading>Topic tags:</Heading>
                  <BubbleGroup>
                    {post.frontmatter.tags &&
                      post.frontmatter.tags.map((tag, index) => (
                        <Link
                          style={{ textDecoration: "none" }}
                          to={`/tags/${tag}`}
                          alt={tag}
                        >
                          <TagBox key={index}>{tag}</TagBox>
                        </Link>
                      ))}
                  </BubbleGroup>
                </section>
              </article>
            </li>
          )
        })}
      </ol>
      <footer>
        <Bio />
      </footer>
    </Layout>
  )
}

export default TagsTemplate

export const tagssQuery = graphql`
  query($distinctTag: [String!]) {
    articles: allMdx(
      sort: { fields: [frontmatter___date], order: ASC }
      filter: { frontmatter: { tags: { in: $distinctTag } } }
      limit: 1000
    ) {
      nodes {
        id
        excerpt
        frontmatter {
          title
          category
          description
          date(formatString: "MMM Do, YYYY")
          tags
        }
        slug
      }
    }
    allTags: allMdx {
      totalCount
      distinct(field: frontmatter___tags)
    }
  }
`
const Header = styled.header`
  h2 {
    font-size: var(--fontSize-3);
    line-height: var(--lineHeight-relaxed);
    color: var(--color-component-green);
  }
  a: {
    text-decoration: none;
  }
  a:hover,
  a:focus {
    text-decoration: none;
    color: var(--color-component-blue);
  }
`

const BubbleGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const TagBox = styled.div`
  font-size: var(--fontSize-2);
  border: 1px solid var(--color-text);
  padding: 2px 5px;
  margin-right: 8px;
  margin-top: 8px;
  white-space: nowrap;
  border-radius: 5px;
  a:hover,
  a:focus {
    color: var(--color-heading-hover);
  }
`
const Heading = styled.h3`
  font-size: var(--fontSize-2);
  text-align: center;
  color: var(--color-export-pink);
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
`
